@import '@styles/config.scss';

.menu {
  @include themed {
    background-color: theme($color-neutral-bg-lowest);
  }

  @include md-lg-viewport {
    width: 35.2rem;
  }
}

.open-menu-button {
  display: none;
  position: relative;

  @include website-media(website-sm-viewport, website-md-viewport) {
    display: inherit;
    right: 3.2rem;
    top: 2rem;
    position: absolute;
    max-width: 2.4rem;
  }

  @include website-md-viewport {
    top: 1.2rem;
    padding: 1.2rem;
    max-width: 4.8rem;
  }

  :global(#react-burger-menu-btn:focus) {
    border-radius: $border-radius-sm;
    @include outline-border;
  }
}

.close-menu-button {
  left: 30.2rem;
  margin-top: 1.2rem;

  @include themed {
    fill: theme($color-neutral-text-high);
  }

  @include sm-viewport {
    left: initial;
    margin-right: 0.8rem;
  }

  :global(#react-burger-cross-btn:focus) {
    border-radius: $border-radius-sm;
    @include outline-border;
  }
}

.menu-overlay {
  top: 0;
  left: 0;

  @include xl-viewport {
    display: none;
  }
}

.menu-icon {
  height: 2.4rem;
  max-width: 2.4rem;
  min-width: 2.4rem;
  @include themed {
    fill: theme($color-neutral-text-highest);
  }
}

.menu-wrapper {
  top: 0;
  left: 0;
  min-height: 100vh;

  @include website-media(website-xl-viewport, website-lg-viewport) {
    display: none;
  }

  .menu-logo {
    margin: 1.6rem 3.2rem 0;

    @include sm-viewport {
      margin: 1.6rem 2.4rem 0;
    }
  }

  .menu-navigation-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 5rem 0;

    .navigation-items {
      margin: 0 3.2rem;

      @include sm-viewport {
        margin: 0 2.4rem;
      }

      .navigation-buttons {
        width: 100%;
        justify-content: flex-start;

        &.active-route-button {
          @include themed {
            background-color: theme($color-neutral-400-opacity-light);
          }
        }
      }
    }

    .menu-buttons {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding-top: 3.2rem;
      margin: 1.6rem 3.2rem 0;
      border-top: $border-width-thin solid;

      @include themed {
        border-color: theme($color-neutral-bg-high);
      }

      @include sm-viewport {
        padding-top: 3.2rem;
        margin: 1.6rem 2.4rem 0;
      }
    }
  }
}

// Burger menu icon variants
// Default
.transparent,
.blurred,
.primary-brand-base,
.primary-brand-base-alt,
.ext-brand-teal,
.ext-brand-teal-alt,
.ext-deep-blue {
  @include themed {
    fill: theme($color-neutral-bg-lowest);

    :global(#react-burger-menu-btn:focus) {
      outline-color: theme($color-neutral-bg-lowest);
    }
  }
}

.highlight-alt-base,
.secondary-brand-low,
.primary-brand-low,
.neutral-background {
  @include themed {
    fill: theme($color-neutral-text-high);
  }
}
