@import '@styles/config.scss';

.footer {
  width: 100%;
  display: flex;
  justify-content: center;

  @include themed {
    background-color: theme($color-brand-deep-blue);
  }

  .footer-wrapper {
    width: 100%;
    padding: 0 7.8rem;
    @include website-max-width;
    @include themed {
      border-top: $border-width-thin solid $website-color-brand-teal-opacity-semi-opaque;
    }

    @include website-md-viewport {
      padding: 0 4.8rem;
    }

    @include website-sm-viewport {
      padding: 0 3.2rem;
    }

    .footer-navigation {
      width: 100%;
      padding-top: 4.8rem;

      @include website-sm-viewport {
        padding-top: 4rem;
      }

      .main-content-nav-container {
        display: flex;
        column-gap: 2.4rem;
        padding-bottom: 8.4rem;
        border-bottom: $border-width-thin solid $website-color-brand-teal-opacity-semi-opaque;

        @include website-sm-viewport {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 4.8rem;
        }

        .section-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-width: 13.7rem;

          @include website-md-viewport {
            min-width: 13.4rem;
          }

          @include website-sm-viewport {
            min-width: auto;
          }

          .section-title {
            margin-bottom: 0.8rem;
            @include heading-md;

            @include themed {
              color: theme($color-secondary-brand-base);
            }
          }
        }
      }

      .additional-content-nav-container {
        padding: 3.2rem 0 5.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4.8rem;
        margin: 0 auto;

        @include website-md-viewport {
          display: grid;
          grid-template: auto auto / auto auto;
          grid-template-areas: 'media media' 'logo policy';
          row-gap: 4.8rem;
          padding: 2.4rem 0 4.8rem;
        }

        @include website-sm-viewport {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-areas: 'media' 'logo' 'policy';
          row-gap: 2.4rem;
          padding: 2.4rem 0 4rem;
        }

        .logo {
          @include website-media(website-md-viewport, website-sm-viewport) {
            grid-area: logo;
          }

          @include website-sm-viewport {
            margin-top: 2.4rem;
            justify-self: center;
          }
        }

        .policy-container {
          flex: 1;
          display: flex;
          column-gap: 3.2rem;

          @include website-media(website-md-viewport, website-sm-viewport) {
            grid-area: policy;
            row-gap: 2.4rem;
          }

          @include website-sm-viewport {
            margin-top: 0.8rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }

        .social-media-container {
          display: flex;
          column-gap: 1.6rem;
          align-items: center;
          justify-content: center;

          @include website-media(website-md-viewport, website-sm-viewport) {
            grid-area: media;
          }

          @include website-sm-viewport {
            max-width: 20rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1.6rem;
            justify-self: center;
          }
        }
      }
    }
  }
}

.link {
  @include themed {
    color: theme($color-neutral-bg-low);
  }
}
