@import '@styles/config.scss';

.link {
  display: inline-flex;
  align-items: center;
  @include themed {
    color: theme($color-primary-brand-base);
  }

  &.medium {
    @include link;
  }

  &.large {
    @include link-large;
  }

  &:focus {
    border-radius: $border-radius-xxs;
    @include outline-border;
  }
}

.icon {
  min-width: 1.6rem;
  width: 1.6rem;
  height: 1.6rem;
  @include themed {
    fill: theme($color-primary-brand-base);
  }
}

.start-icon {
  margin-right: 0.8rem;
}

.end-icon {
  margin-left: 0.8rem;
}
