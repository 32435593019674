@import '../../styles/config.scss';

.container {
  svg {
    @include themed {
      stroke: theme($color-neutral-text-highest);
    }
  }

  circle {
    @include themed {
      stroke: theme($color-neutral-bg-highest);
    }
  }

  &.disabled {
    svg {
      @include themed {
        stroke: theme($color-neutral-text-lowest);
      }
    }

    circle {
      @include themed {
        stroke: theme($color-neutral-bg-highest);
      }
    }
  }

  &.x-small {
    svg {
      width: 1.6rem;
      height: 1.6rem;

      g circle {
        stroke-width: 0.2rem;
      }
    }
  }

  &.small {
    svg {
      width: 2rem;
      height: 2rem;

      g circle {
        stroke-width: 0.2rem;
      }
    }
  }

  &.medium {
    svg {
      width: 2.4rem;
      height: 2.4rem;

      g circle {
        stroke-width: 0.25rem;
      }
    }
  }

  &.large {
    svg {
      width: 3.2rem;
      height: 3.2rem;

      g circle {
        stroke-width: 0.3rem;
      }
    }
  }
}
