@import '@styles/config.scss';

@mixin bg-transition {
  transition: opacity 0.8s ease-in-out, background-color 0.3s ease-in-out;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  @include bg-transition;

  .header-content {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 1.2rem 8rem;
    @include website-max-width;

    @include website-md-viewport {
      padding: 1.2rem 2.4rem;
    }

    @include website-sm-viewport {
      padding: 2rem 2rem 2rem 1.6rem;
    }

    .link {
      margin-right: 4.8rem;

      &.outline-color-neutral {
        @include themed {
          outline-color: theme($color-neutral-bg-low);
        }
      }

      &.outline-color-yellow {
        outline-color: yellow;
      }
    }

    .navigation-wrapper {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      gap: 2.4rem;

      @include website-media(website-md-viewport, website-sm-viewport) {
        display: none;
      }

      .active-route-button {
        @include themed {
          background-color: theme($color-neutral-400-opacity-light);
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 1.6rem;

      @include website-md-viewport {
        flex: 1;
        justify-content: flex-end;
        margin-right: 7.6rem;
      }

      @include sm-viewport {
        display: none;
      }
    }
  }
}

// Header Styles on bg-color change
// Default
.transparent {
  @include themed {
    background-color: transparent;
  }
}

.blurred {
  @include themed {
    background-color: transparent;
    backdrop-filter: blur(4rem);
  }
}

.neutral-background {
  @include themed {
    background-color: theme($color-neutral-bg-lowest);
  }
}

.primary-brand-base {
  @include themed {
    background-color: theme($color-primary-brand-base);
  }
}

.primary-brand-base-alt {
  @include themed {
    background-color: theme($color-primary-brand-base);
  }
}

.primary-brand-low {
  @include themed {
    background-color: theme($color-primary-brand-low);
  }
}

.ext-brand-teal,
.ext-brand-teal-alt {
  @include themed {
    background-color: theme($color-brand-teal);
  }
}

.ext-deep-blue {
  @include themed {
    background-color: theme($color-brand-deep-blue);
  }
}

.secondary-brand-low {
  @include themed {
    background-color: theme($color-secondary-brand-low);
  }
}

.secondary-brand-base {
  @include themed {
    background-color: theme($color-secondary-brand-base);
  }
}

.highlight-alt-base {
  @include themed {
    background-color: theme($color-highlight-alt-base);
  }
}
