@import '@styles/config.scss';

.button {
  border-radius: $border-radius-sm;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;

  &:focus {
    @include outline-border;
  }

  @include heading-xs;

  // Variants
  &.ghost {
    border-color: transparent;
    @include themed {
      color: theme($color-neutral-text-highest);
    }

    .start-adornment,
    .end-adornment {
      @include themed {
        fill: theme($color-neutral-text-highest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-neutral-400-opacity-light);
      }
    }

    &:focus {
      outline: $border-width-thick solid;
      outline-offset: $border-width-thick;

      @include themed {
        outline-color: theme($color-neutral-bg-lowest);
      }
    }

    &:disabled {
      @include themed {
        color: theme($color-neutral-text-lowest);
      }

      .start-adornment,
      .end-adornment {
        @include themed {
          fill: theme($color-neutral-bg-highest);
        }
      }
    }
  }

  &.ghost-on-brand {
    @include themed {
      background-color: transparent;
      color: theme($color-neutral-bg-lowest);
      border-color: transparent;
    }

    .start-adornment,
    .end-adornment {
      @include themed {
        fill: theme($color-neutral-bg-lowest);
      }
    }

    &:hover {
      @include themed {
        background-color: theme($color-neutral-400-opacity-light);
      }
    }

    &:focus {
      outline: $border-width-thick solid;
      outline-offset: $border-width-thick;

      @include themed {
        outline-color: theme($color-neutral-bg-lowest);
      }
    }

    &:disabled {
      @include themed {
        color: theme($color-neutral-text-lowest);
      }

      .start-adornment,
      .end-adornment {
        @include themed {
          fill: theme($color-neutral-text-lowest);
        }
      }
    }
  }

  &.outline-primary {
    &:focus {
      @include themed {
        outline-color: theme($color-primary-brand-base);
      }
    }
  }

  &.outline-neutral {
    &:focus {
      @include themed {
        outline-color: theme($color-neutral-bg-low);
      }
    }
  }

  // Sizes
  .label {
    text-decoration: inherit;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    white-space: inherit;

    &.loading {
      visibility: hidden;
    }

    &.large {
      padding: 1.4rem;
      box-sizing: border-box;
      gap: 1.2rem;

      .adornment {
        min-width: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &.medium {
      padding: 1rem 1.2rem;
      gap: 0.8rem;

      .adornment {
        min-width: 2rem;
        width: 2rem;
        height: 2rem;
      }
    }

    &.small {
      padding: 0.6rem 1.2rem;
      gap: 0.8rem;

      .adornment {
        min-width: 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
