@use 'sass:map';
@import 'themes.scss';

// Border Radius
$border-radius-xxs: 0.4rem;
$border-radius-xs: 0.6rem;
$border-radius-sm: 0.8rem;
$border-radius-md: 1.2rem;
$border-radius-lg: 1.6rem;
$border-radius-xl: 2rem;
$border-radius-xxl: 2.4rem;
$border-radius-full: 50%;

// Border Width
$border-width-thin: 0.1rem;
$border-width-thick: 0.2rem;
$primary-border: 0 0 0 0.2rem var(--primary);
$secondary-border: 0 0 0 0.2rem var(--secondary);

@mixin outline-border {
  outline: $border-width-thick solid;
  outline-offset: $border-width-thick;

  @include themed {
    outline-color: theme($color-primary-brand-base);
  }
}
