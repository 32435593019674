@import '../../styles/config.scss';

.logo {
  &.primary {
    @include themed {
      fill: theme($color-primary-brand-base);
    }
  }

  &.secondary {
    @include themed {
      fill: theme($color-secondary-brand-base);
    }
  }

  &.white {
    @include themed {
      fill: theme($color-neutral-bg-lowest);
    }
  }
}
