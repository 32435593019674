@import '@styles/config.scss';

.link {
  padding: 0.8rem 0.2rem;
  text-decoration: none;
  text-underline-offset: 0.2rem;
  @include subtitle-short-lg;
  @include themed {
    color: theme($color-neutral-bg-lowest);
  }

  &:focus {
    border-radius: $border-radius-xxs;
    outline: $border-width-thick solid;
    outline-offset: 0.2rem;
    @include themed {
      outline-color: theme($color-neutral-bg-lowest);
    }
  }

  &:hover {
    text-decoration: underline;

    @include themed {
      color: theme($color-secondary-brand-low);
    }
  }
}
